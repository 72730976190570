import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableLabConsulting = loadable(() => import('components/LaboratoryConsultingPage'), {
  fallback: (
    <Spinner />
  ),
});

const LabConsultPage = () => {
  return <LoadableLabConsulting />;
};

export default LabConsultPage;
